import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DerEinzelneUndDieGesellschaftZitate = () => (
    <InnerPage
        title="Zitate – Der Einzelne und die Gesellschaft"
        description=""
    >
        <IntroHolder
            title="Zitate"
            subtitle="Der Einzelne und die Gesellschaft"
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Der Einzelne und die Gesellschaft',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Verborgenen Worte, Arabisch 2">
                    O Sohn des Geistes!
                    <br />
                    Von allem das Meistgeliebte ist Mir die Gerechtigkeit. Wende
                    dich nicht ab von ihr, wenn du nach Mir verlangst, und
                    vergiß sie nicht, damit Ich dir vertrauen kann. Mit ihrer
                    Hilfe sollst du mit eigenen Augen sehen, nicht mit denen
                    anderer, und durch eigene Erkenntnis Wissen erlangen, nicht
                    durch die deines Nächsten. Bedenke im Herzen, wie du sein
                    solltest. Wahrlich, Gerechtigkeit ist Meine Gabe und das
                    Zeichen Meiner Gnade. So halte sie dir vor Augen.
                </NormalQuote>

                <NormalQuote reference="Das Universale Haus der Gerechtigkeit, Ridván 2010">
                    Wenn auch die Bedingungen von Land zu Land…unterschiedlich
                    sind, …gibt es doch gewisse grundlegende Konzepte, die alle
                    berücksichtigen sollten. Das eine ist der zentrale Einfluss,
                    den Wissen auf das soziale Leben ausübt. Unwissenheit
                    fortbestehen zu lassen ist die schlimmste Form der
                    Unterdrückung; es verstärkt die vielen Mauern des
                    Vorurteils, die als Barrieren vor der Verwirklichung der
                    Einheit der Menschheit stehen – zugleich Ziel und
                    Wirkungsprinzip von Bahá’u’lláhs Offenbarung. Der Zugang zu
                    Wissen ist das Recht eines jeden Menschen, und mitzuhelfen,
                    Wissen zu generieren, anzuwenden und zu verbreiten ist eine
                    Verantwortung, die alle schultern müssen in dem großen
                    Unternehmen, eine blühende Weltzivilisation aufzubauen –
                    wobei jeder seine oder ihre eigenen Talente und Fähigkeiten
                    einsetzt. Gerechtigkeit erfordert universelle Teilhabe.
                </NormalQuote>

                <NormalQuote reference="Das Universale Haus der Gerechtigkeit, 28.12.2010">
                    …nämlich dass der Fortschritt durch die Entwicklung von drei
                    Beteiligten erreicht wird — dem Einzelnen, den Institutionen
                    und der Gemeinde. Während der gesamten Menschheitsgeschichte
                    war das Zusammenspiel dieser drei auf Schritt und Tritt mit
                    Schwierigkeiten belastet: mit dem Verlangen des Einzelnen
                    nach Freiheit, der Forderung der Institutionen nach
                    Unterordnung und dem Anspruch der Gemeinde auf den Vorrang.
                    Jede Gesellschaft hat auf die eine oder andere Weise die
                    Beziehungen, welche die drei aneinander binden, festgelegt
                    und so Zeiten der Stabilität hervorgerufen, durchwirkt von
                    Aufruhr. Heute, in diesem Zeitalter des Übergangs, da die
                    Menschheit um ihre kollektive Reife kämpft, werden solche
                    Beziehungen — nein, allein schon die Vorstellung vom
                    Einzelnen, von den gesellschaftlichen Institutionen und der
                    Gemeinde — weiterhin von unzähligen Krisen heimgesucht. Die
                    weltweite Autoritätskrise liefert einen hinreichenden
                    Beweis. So schwerwiegend wurde Autorität missbraucht und
                    verletzt, und so tief ist das Misstrauen und die
                    Feindseligkeit, die sie nun hervorruft, dass die Welt
                    zunehmend unregierbar wird — eine Situation, die durch die
                    Schwächung von Gemeinschaftsbanden noch gefährlicher wird.
                    <br />
                    Jeder Anhänger Bahá’u’lláhs weiß wohl, dass es der Zweck
                    Seiner Offenbarung ist, eine neue Schöpfung hervorzubringen.
                    „Kaum war … der Erste Ruf Seinen Lippen entströmt, da war
                    die ganze Schöpfung umgewälzt; alle in den Himmeln und alle
                    auf Erden wurden bis tief ins Herz aufgewühlt.“ Der
                    Einzelne, die Institutionen und die Gemeinde — die drei
                    Protagonisten des göttlichen Plans — werden unter dem
                    unmittelbaren Einfluss Seiner Offenbarung geformt, und eine
                    neue Auffassung jeder der drei ist im Entstehen begriffen in
                    einer für eine Menschheit angemessenen Weise, die die Reife
                    erlangt hat. Die Beziehungen, die sie verbinden, durchleben
                    ebenfalls eine tiefgreifende Wandlung und bringen dabei
                    Kultur stiftende Kräfte ins Reich des Daseins, die nur durch
                    Übereinstimmung mit Seinem Gebot freigesetzt werden können.
                    Auf grundlegender Ebene sind diese Beziehungen
                    gekennzeichnet durch Zusammenarbeit und Wechselseitigkeit –
                    Ausdruck der Vernetzung, die das Universum regiert. Also
                    betrachtet sich der Einzelne ungeachtet seines „persönlichen
                    Nutzens und eigensüchtigen Vorteils“ als einen „Diener
                    Gottes, des Allbesitzenden“, dessen einziges Verlangen es
                    ist, Seine Gesetze zu befolgen.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das geheimnis göttlicher Kultur">
                    Die politische Welt ist wie die des Menschen: Dieser ist am
                    Anfang nur Same und schreitet dann stufenweise zum Zustand
                    des Embryos und Foetus, wobei er ein Knochengerüst erhält,
                    mit Fleisch umgeben wird und seine eigentliche Gestalt
                    annimmt, bis er schließlich die Stufe erreicht, auf der er
                    schicklich das Wort erfüllt: »… der erhabenste der
                    Schöpfer«. Dies ist ein Erfordernis der Schöpfung und in der
                    Weisheit des Allumfassenden begründet. In gleicher Weise
                    kann sich deshalb auch die politische Welt nicht plötzlich
                    vom Nadir der Zerrüttung zum Zenith der Rechtlichkeit und
                    Vollkommenheit entwickeln. Befähigte Persönlichkeiten müssen
                    sich vielmehr Tag und Nacht bemühen und alle Mittel, die zum
                    Fortschritt führen, anwenden, damit sich Regierung und Volk
                    Stufe um Stufe entwickeln, Tag für Tag, ja Stunde für
                    Stunde.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DerEinzelneUndDieGesellschaftZitate;
